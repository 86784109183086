html {
  height: 100%;
}

title {
  display: block;
}

body {
  text-align: center;
  height: calc(100% - 2em);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 1em;
  font-family: sans-serif;
  display: flex;
}

#qr {
  width: calc(min(80vw, 80vh));
  height: calc(min(80vw, 80vh));
}

/*# sourceMappingURL=index.e68c4fbb.css.map */
