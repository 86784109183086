html {
    height: 100%;
}

title {
    display: block;
}
body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: calc(100% - 2em);
    margin: 1em;
    font-family: sans-serif;
}

#qr {

    width: calc(min(80vw, 80vh));
    height: calc(min(80vw, 80vh));
}
